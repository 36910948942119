<template>
	<v-overlay
		:visible="visible"
		@close="close"
	>
		<div class="flex space-between align-center mb-30">
			<div>
				<h1>{{ editing ? 'Rætta' : 'Stovna' }} persón</h1>
			</div>

			<div
				class="overlay-close"
				@click.prevent="close"
			></div>
		</div>

		<div class="grid grid-2 grid-gap-x-20">
			<InputGroup
				v-model="value.name"
				:required="true"
				label="Navn"
				:error="$v.value.name.$invalid && $v.value.name.$dirty"
				:errorMessage="'Vinaliga skriva eitt navn'"
				@blur="$v.value.name.$touch()"
			/>

			<InputGroup
				v-model="value.email"
				:required="true"
				label="Teldupostadressa"
				:error="$v.value.email.$invalid && $v.value.email.$dirty"
				:errorMessage="'Vinaliga skriva ein teldupost'"
				@blur="$v.value.email.$touch()"
			/>

			<InputGroup
				v-model="value.ssn"
				:required="true"
				label="P-Tal"
			/>

			<InputGroup
				v-model="value.jobTitle"
				label="Starvsheiti"
			/>

			<InputGroup
				v-model="value.livingCountry"
				label="Tjóðskapur"
				:required="true"
				:error="$v.value.livingCountry.$invalid && $v.value.livingCountry.$dirty"
				:errorMessage="'Hetta er ein kravdur teigur'"
				@blur="$v.value.livingCountry.$touch()"
			/>

			<InputGroup
				v-model="value.taxationCountry"
				label="Skattskyldugt land"
				:required="true"
				:error="$v.value.taxationCountry.$invalid && $v.value.taxationCountry.$dirty"
				:errorMessage="'Hetta er ein kravdur teigur'"
				@blur="$v.value.taxationCountry.$touch()"
			/>
		</div>

		<div class="grid grid-2 grid-gap-x-20">
			<div>
				<label class="mb-10">Er persónurin eigari í fyritøkuni?</label>

				<YesNo v-model="value.isOwner" />
			</div>

			<transition name="slide-fade">
				<div v-if="value.isOwner">
					<InputGroup
						v-model="value.ownership"
						:required="true"
						label="Ognarskapur (%)"
						:error="$v.value.ownership.$error"
						:errorMessage="'Vinaliga eitt tal millum 0 og 100'"
						@blur="$v.value.ownership.$touch()"
					/>
				</div>
			</transition>
		</div>

		<div class="grid grid-2 grid-gap-x-20">
			<div>
				<label class="mb-10">Kann persónurin undirskriva vegna felagið?</label>

				<YesNo v-model="value.canSign" />
			</div>

			<transition name="slide-fade">
				<div
					v-if="value.canSign"
					:class="{ disabled: hasMaxSignatures }"
				>
					<label class="mb-10">Skal persónurin undirskriva hetta skjalið?</label>

					<YesNo v-model="value.signature" />
				</div>
			</transition>
		</div>

		<div
			class="mb-30"
			:class="{ disabled: contactPerson && contactPerson.id != value.id }"
		>
			<label class="mb-10">Er persónurin kontaktpersónur fyri hesa avtaluna?</label>

			<YesNo v-model="value.isContactPerson" />
		</div>

		<div class="mb-30">
			<label class="inline group mb-10">
				Politisk Eksponeret Person (PEP)

				<span class="color-gray">
					<Tooltip large>
						<div class="mb-30">
							<h2 class="mb-10">Politisk udsat person (PEP)</h2>

							<p>
								Med PEP menes en person, som har eller tidligere har haft (inden for de seneste 18 måneder) en politisk udsat post eller en høj
								statslig stilling, eller som har et nært familiemedlem, eller er en nær medarbejder til en sådan person.
							</p>
						</div>

						<p>
							Er der (1) nogen af repræsentanterne (II) reelle ejere eller (III) familiemedlemmer eller kendte medarbejdere af nogen af disse, der
							har eller har haft en af følgende roller:
						</p>

						<ul>
							<li>Statschefer, regeringschefer, ministre og viceministre eller assisterende ministre.</li>
							<li>Parlamentsmedlemmer.</li>
							<li>Højesteretsdommere, medlemmer af forfatningsdomstole og af andre højtstående retsinstanser, hvis</li>
							<li>beslutninger kun er genstand for yderligere prøvelse under ekstraordinære omstændigheder.</li>
							<li>Medlemmer af revisionsretter og af bestyrelser for centralbanker.</li>
							<li>Ambassadører, chargés d'affaires og højtstående officerer i de væbnede styrker.</li>
							<li>Medlemmer af statsejede virksomheders administrative, ledende eller kontrollerende organer.</li>
						</ul>
					</Tooltip>
				</span>
			</label>

			<YesNo v-model="value.pep" />
		</div>

		<div class="mb-20">
			<label class="block mb-10">Pass ella koyrikort</label>

			<p class="color-grey">Viðheft litmynd av galdandi passi ella koyrikorti.</p>
		</div>

		<upload-identification
			v-if="value.name"
			:owner-name="value.name"
			:customer-uuid="customerUuid"
			:agreement-uuid="agreementUuid"
			v-model="value.file"
		></upload-identification>

		<div class="flex space-between">
			<div
				class="btn"
				v-if="(!value.temporary && editing) || (!$v.value.$invalid && value.file.uuid)"
				:class="{ disabled: $v.value.$invalid || !value.file.uuid }"
				@click.prevent="saveOwner()"
			>
				{{ editing && !value.temporary ? 'Rætta' : 'Stovna' }}
			</div>

			<div
				class="btn"
				v-if="(value.temporary || !editing) && ($v.value.$invalid || !value.file.uuid)"
				@click.prevent="saveOwner(true)"
			>
				Goym fyribils
			</div>

			<div
				v-if="editing"
				class="btn background-red"
				@click.prevent="deleteOwner"
			>
				Strika
			</div>
		</div>
	</v-overlay>
</template>

<script>
import axios from 'axios';
import { between, email, required, requiredIf } from 'vuelidate/lib/validators';

import InputGroup from '@/components/InputGroup.vue';
import Tooltip from '@/components/Tooltip.vue';
import UploadIdentification from '@/components/UploadIdentification.vue';
import VOverlay from '@/components/VOverlay.vue';
import YesNo from '@/components/YesNo.vue';

export default {
	name: 'OwnerOverlay',

	props: {
		visible: Boolean,
		value: Object,
		editing: Boolean,
		agreementUuid: String,
		customerUuid: String,
		hasMaxSignatures: Boolean,
		contactPerson: Object,
	},

	components: {
		VOverlay,
		InputGroup,
		UploadIdentification,
		YesNo,
		Tooltip,
	},

	data() {
		return {
			hasChanges: false,
			timeout: null,
			timeoutExpired: false,
		};
	},

	watch: {
		visible: async function (visible) {
			if (!visible) {
				return;
			}

			this.hasChanges = false;
			this.timeoutExpired = false;

			clearTimeout(this.timeout);

			this.timeout = setTimeout(() => {
				this.timeoutExpired = true;
			}, 300);

			if (this.hasMaxSignatures && !this.value.signature) {
				this.value.signature = false;
			}

			if (this.contactPerson && this.contactPerson.id != this.value.id) {
				this.value.isContactPerson = false;
			}

			if (this.value.file && !this.value.file.url) {
				await axios
					.get(`/files/${this.value.file.uuid}/url`)
					.then((response) => {
						// this.value.file = { ...this.value.file, url: response.data };
						this.$emit('update-file-url', response.data);
					})
					.catch((e) => {
						console.log(e);
					});
			}
		},

		'value.isOwner': function (isOwner) {
			if (!isOwner) {
				this.value.ownership = '';
			}
		},

		value: {
			deep: true,

			handler: function () {
				if (this.timeoutExpired) {
					this.hasChanges = true;
				}
			},
		},
	},

	methods: {
		saveOwner(temporary) {
			if (this.value.ownership) {
				this.value.ownership = Number(this.value.ownership);
			} else {
				this.value.ownership = 0;
			}

			this.$emit('saveOwner', temporary);

			this.hasChanges = false;

			this.close();
		},

		close() {
			if (this.hasChanges) {
				if (!confirm('Tú hevur gjørt rættingar uttan at goyma. Ert tú vís/ur í, at tú ynskir at sløkkja hendan skermin?')) {
					return;
				}
			}

			this.$v.$reset();

			this.$emit('close');

			this.hasChanges = false;
		},

		deleteOwner() {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			if (this.value.temporary) {
				this.$emit('delete-temporary', this.value.id);
				this.$emit('close');

				return;
			}

			const token = this.$route.query.token;

			axios
				.delete(`/agreement-links/${token}/owner/${this.value.id}`)
				.then(() => {
					this.$emit('deleteOwner', this.value);
					this.close();
				})
				.catch((error) => console.log(error));
		},
	},

	validations: {
		value: {
			name: {
				required,
			},

			email: {
				required,
				email,
			},

			ssn: {
				required,
			},

			livingCountry: {
				required,
			},

			taxationCountry: {
				required,
			},

			isOwner: {
				required,
			},

			ownership: {
				between: between(0, 100),
				required: requiredIf((owner) => {
					return owner.isOwner;
				}),
			},

			isContactPerson: {
				required: requiredIf(function () {
					return !(this.contactPerson && this.contactPerson.id != this.value.id);
				}),
			},

			canSign: {
				required,
			},

			signature: {
				required: requiredIf((owner) => {
					return owner.canSign;
				}),
			},

			pep: {
				required,
			},

			file: {
				required,
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.disabled {
	opacity: 0.33;
	pointer-events: none;
}
</style>
